import './About.css';
import { forwardRef } from 'react';
import ukf from '../images/ukraine_flag.png';
// import pp1 from '../images/dev_image.png';
// import pp2 from '../images/dev_image.png';
// import pp3 from '../images/dev_image.png';

const About = forwardRef((props, ref) => {
    return (
        <section className='about' ref={ref}>
            <div className='about-container'>
                <h2 className='font'>About</h2>
                {/* <div className='about-gallery'>
                    {[stj, pp3, stj].map(img_src => <img src={img_src}
                        alt='Punks and StJude'
                        key={Math.random().toString()} />)}
                </div> */}
                <p className='paragraph fs padding'>
                PauliePunks NFTs is a project I created to learn more about generative art, NFTs, IPFS and web3.
                Each PauliePunk is a uniquely generated piece of art that lives on the Ethereum blockchain and follows the ERC721 standard.
                With this being a learning opportunity, I did not want to capitalize on it so all proceeds from the mint will be going towards
                helping Ukraine. <a href="https://twitter.com/Ukraine/status/1497594592438497282?s=20&t=NV6C3JakqaeDsgV-DeIx4g"
                target="_blank"
                rel="noopener noreferrer">
                Learn more about the Ethereum address we will be donating to for Ukraine.</a>
                </p>
                <p className='paragraph fs padding'>
                After the mint, PauliePunks will be available on the OpenSea secondary market. 2.5% of each
                trade will go towards the charity of the community's choice. As an incentive, hodlers of PauliePunks will
                be eligible for future airdrops and will have priority access to all future projects I work on.
                </p>
                <p className='paragraph fs padding'>
                Thanks for donating to a good cause and I'm looking forward to seeing you all with your
                PauliePunks in the metaverse!
                </p>
                <div className='padding' align='center'>
                    <a href="https://twitter.com/Ukraine/status/1497594592438497282?s=20&t=NV6C3JakqaeDsgV-DeIx4g"
                       target="_blank"
                       rel="noopener noreferrer">
                    <img className='resize' src={ukf} alt='Ukraine Flag'/>
                </a>
        </div>
            </div>
        </section>
    )
});

export default About;