import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Header.css';

function Header(props) {

  const [/*scrollPosition*/, setScrollPosition] = useState(0);
  
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const buttonClickHandler = (section_text) => {
    console.log(section_text);
    props.scrollHandler(section_text);
    }

  return (
    <Navbar collapseOnSelect expand="lg" /* fixed="top" */ bg="transparent"
     className= "size" /* {scrollPosition < 25 ? "size" : "nav size"} */ variant="light">
    <Container>
    <Navbar.Brand className="size font">PauliePunks</Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="ml-auto">
        <button className="font" onClick={() => { buttonClickHandler('mint') }}>Mint</button>
        <button className="font" onClick={() => { buttonClickHandler('about') }}>About</button>
        <button className="font" onClick={() => { buttonClickHandler('faq') }}>FAQ</button>
      </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}

export default Header;