import { useEffect, useState } from 'react';
import { ethers, utils } from 'ethers';
import { forwardRef } from 'react';
import './Mint.css';
import contract_abi from '../contracts/NFTCollectible.json'
import pauliepunksgif from '../images/pauliepunks.gif'
import opensea_logo from '../images/opensea_logo.png'
import twitter_logo from '../images/twitter_logo.png'
import discord_logo from '../images/discord_logo.png'
import Container from "react-bootstrap/Container";
import Countdown from 'react-countdown';

const contractAddress = '0x42f7C47E4dE2540f619a9650999aaaA64d64044C'
const abi = contract_abi
const MAX_COUNT = 3333
const MAX_MINT = 10
const COUNTDOWN_DATE = '2022-04-17T00:00:00'

const Mint = forwardRef((props, ref) => {

  const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    return {
      value,
      setValue,
      bind: {
        value,
        onChange: event => {
          setValue(event.target.value);
        }
      }
    };
  };

  const { value, bind } = useInput(1);
  
  // const handleSubmit = (evt) => {
  //     evt.preventDefault();
  //     alert(`Submitting Name ${value}`);
  //     reset();
  // }


  const [currentAccount, setCurrentAccount] = useState(null);
  const [mintCount, setMintCount] = useState(null);

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    } else {
      console.log("Wallet exists! We're ready to go!")
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }
  }


  const connectWalletHandler = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (err) {
      console.log(err)
    }
  }

  const mintNftHandler = async (evt) => {

    if (new Date(COUNTDOWN_DATE) > Date.now()){
      evt.preventDefault();
      alert(`Mint Day is 4/17/22`);
    } else {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        if (value > MAX_MINT){
          evt.preventDefault();
          alert(`Cannot mint more than 10 per txn.`);
        } else {

          console.log("Initialize payment");
          console.log(value);
          console.log((0.01*value).toString());
          
          let nftTxn = await nftContract.mintNFTs(value, { value: utils.parseEther((0.01*value).toString()) });

          console.log("Mining... please wait");
          await nftTxn.wait();

          console.log(`Mined, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
        }
      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      console.log(err);
    }
    }
  }

  const getMintedCount = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        let mint_count = await nftContract.totalSupply();

          if (mint_count) {
            setMintCount(parseInt(mint_count._hex, 16));
          }

      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      console.log(err);
    }
  }

  const connectWalletButton = () => {
    return (
      <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
        Connect Metamask
      </button>
    )
  }

  const mintNftButton = () => {
    return (
      <button onClick={mintNftHandler} className='cta-button mint-nft-button'>
        Mint NFT
      </button>
    )
  }

  let mint_display = (
    <div align='center'>
    {/* <h1 className='mobile'>Mint Here</h1> */}
    <div className='padding'> {connectWalletButton()} </div>
    </div>
  );

  if (currentAccount) {
    getMintedCount();
    mint_display = (
      <div align='center'>
      {/* <h1 className='mobile'>Mint Here</h1> */}
      <h1 className='padding'>{mintCount ? mintCount : 0} / {MAX_COUNT}</h1>
      <h2 className='fs padding'>0.01 ETH Each</h2>
      <h2 className='fs'>Max 10 Mints / txn </h2>
      {/* <form onSubmit={mintNftHandler} className='vertical-center'> */}
      <label className='padding'>
        <input type="text" {...bind} />
      </label>
      {/* <input type="submit" value="Mint NFT" className='cta-button mint-nft-button' />
      </form> */}
      <div className='padding'> {mintNftButton()} </div>
      </div>
    );
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
      return (
        <div>
        <h1 className='mobile'>Mint Here</h1>
        {mint_display}
        </div>
      );
      } else {
        // Render a countdown
      return (
      <div>
      <h2 className='mobile'>Mint Countdown</h2>
      <span className='countdown'>{days} : {hours} : {minutes} : {seconds}</span>
      {mint_display}
      </div>
      );
    }
  };

  useEffect(() => {
    checkWalletIsConnected();
    getMintedCount();
  }, [])

  return (
    <section className='jumbotron' ref={ref}>

            <div className='mint-container'>
              
                <div className='jt-image'>
                  <img src={pauliepunksgif} alt='Gallery of Punks' />
                </div>
                <Container align='center' className='vertical-center'>

                  <Countdown date={COUNTDOWN_DATE} renderer={renderer} />
                  {/* <h1>Mint Here</h1> */}
                  {/* {mint_display} */}
                  {/* <h1 className='padding'>{mintCount ? mintCount : 0} / {MAX_COUNT}</h1>
                  <div className='padding'>{currentAccount ? mintNftButton() : connectWalletButton()}</div> */}
                  <div className='flex padding pd'>
                  <a
                        href="https://twitter.com/PauliePunks"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img src={twitter_logo} alt='Twitter'
                        style={{
                        width: 75,
                        height: 75
                      }}></img>
                    </a>
                    <a
                        href="https://discord.gg/vCz9VMwmx4"
                        target="_blank"
                        rel="noopener noreferrer">
                      <img src={discord_logo} alt='Discord'
                      style={{
                        width: 75,
                        height: 75
                      }}></img>
                    </a>
                    <a href='https://opensea.io/collection/pauliepunks' 
                       target='_blank' 
                       rel='noopener noreferrer'>
                      <img src={opensea_logo} alt='Opensea'
                      style={{
                        width: 75,
                        height: 75
                      }}></img>
                    </a>
                    </div>
       
                </Container>
            </div>

        </section>
  )
});

export default Mint;
