const data = {
    rows: [
        {
            title: "What is an NFT?",
            content: "<a href='https://ethereum.org/en/nft' target='_blank' rel='noopener noreferrer'>Learn about NFTs here.</a>",
        },
        {
            title: "What are PauliePunks NFTs?",
            content: "PauliePunks NFTs are uniquely generated digital images that I created to learn more about web3 development. All proceeds of this project will go directly to charity.",
        },
        {
            title: "How can I mint a PauliePunk?",
            content: "To mint a PauliePunk you will need to connect a MetaMask wallet. <a href='https://metamask.io/faqs' target='_blank' rel='noopener noreferrer'> Learn how to install and fund a MetaMask wallet here.</a>",
        },
        {
            title: "How much does it cost to mint?",
            content: "Each PauliePunk will cost 0.01 ETH to mint and a user can mint 10 PauliePunks per transaction. I would like for this to be affordable and have many people join in on the fun.",
        },
        {
            title: "How many PauliePunks will there be?",
            content: "There will only ever be 3333 unique PauliePunks in existence. 33 will be reserved by the the team for special giveaways and events.",
        },
        {
            title: "How do I know all proceeds will be going to charity?",
            content: "The smart contract's withdraw functionality has been modified to send all funds to the Ukraine Government's Ethereum Address: <a href='https://twitter.com/Ukraine/status/1497594592438497282?s=20&t=NV6C3JakqaeDsgV-DeIx4g' target='_blank' rel='noopener noreferrer'> 0x165CD37b4C644C2921454429E7F9358d18A45e14.</a> This address has been hardcoded and there is no way for me to withdraw the funds to my personal address. The smart contract will be verified and shared publicly once it goes live.",
        },
        {
            title: "Secondary market?",
            content: "After the mint PauliePunks NFTs will be available on OpenSea's secondary market.",
        },
        {
            title: "Rarity?",
            content: "Rarity lists will come soon.",
        },
        {
            title: "Rights and ownership?",
            content: "The owner of a PauliePunk has full rights and ownership and can use or display the NFT in any way that they choose.",
        }
    ],
};

export default data;