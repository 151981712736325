import React, { forwardRef } from "react";
import Faq from "react-faq-component";
import './Faq.css';
import data from '../helpers/faq'

const Fandq = forwardRef((props, ref) => {

    const styles = {
        rowTitleTextSize: '25px'
    };

    return (
        <section className='faq' ref={ref}>
            <div className='faq-container'>
                <h2 className="font">FAQ</h2>
                <div className="faq-style-wrapper">
                    <Faq
                     data={data}
                     styles={styles}
                    />
                </div>
            </div>
        </section>
    )
});

export default Fandq;