import './Footer.css';

function Footer() {
    return (
        <footer class="text-center">
        <p>Verified Smart Contract: <a href='https://etherscan.io/address/0x42f7c47e4de2540f619a9650999aaaa64d64044c#code' target='_blank' rel='noopener noreferrer'>0x42f7c47e4de2540f619a9650999aaaa64d64044c</a></p>
        <p className='font'> &copy; PauliePunks {new Date().getFullYear()}</p>
      </footer>
    )
}

export default Footer;