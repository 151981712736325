import './Dev.css';
import dev_image from '../images/dev_image.png'


function Dev() {
    return (
        <section className='dev'>
            <div className='dev-container'>
                <h2 className="font">DEV</h2>
                <div align='center' className='padding'>
                    <a href="https://twitter.com/PallabPaulll"
                       target="_blank"
                       rel="noopener noreferrer">
                    <img src={dev_image} alt='PauliePunk Dev'
                    style={{
                    width: 250,
                    height: 250,
                    borderRadius: 250 / 2
                }} />
                <h3 className='padding'>pallab.eth</h3>
                </a>
        </div>
            </div>
        </section>
    )
}

export default Dev;