import { Fragment, useRef } from 'react';
import './App.css';
import Header from './components/Header';
import Mint from './components/Mint';
import About from './components/About';
import Fandq from './components/Faq';
import Dev from './components/Dev';
import Footer from './components/Footer';

function App() {

  const mintRef = useRef(null);
  const aboutRef = useRef(null);
  const faqRef = useRef(null);

  const scrollHandler = (section) => {
    if (section === 'mint') mintRef.current.scrollIntoView({ behavior: 'smooth' });
    if (section === 'about') aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    if (section === 'faq') faqRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div
      style={{
        backgroundColor: 'pink'
      }}
    >
    <Fragment>
      <Header scrollHandler={scrollHandler} />
      <main>
        <Mint ref={mintRef} />
        <hr className="rainbow"></hr>
        <About ref={aboutRef} />
        <hr className="rainbow"></hr>
        <Fandq ref={faqRef} />
        <hr className="rainbow"></hr>
        <Dev />
        <hr className="rainbow"></hr>
      </main>
      <Footer />
    </Fragment>
    </div>
  );
}

export default App;